﻿module.exports = (function () {
  function initPasswordIcons() {
    if ($('.show-password-field').parent().hasClass('form-floating')) {
      //Bootstrap 5 floating form handling
      $('.show-password-field').parent().addClass('show-password-container');
    } else {
      $('.show-password-field').wrap('<div class="show-password-container">');
    }
    $('.show-password-container').append('<button type="button" class="show-password-icon" aria-label="Show Password"><svg viewBox="0 0 40 40" width="40" height="40"><use xlink:href="#icon-eye-strikethrough"></use></svg></button>');
    $('.show-password-container').append('<button type="button" class="hide-password-icon" style="display:none" aria-label="Hide Password"><svg viewBox="0 0 40 40" width="40" height="40"><use xlink:href="#icon-eye-open"></use></svg></button>');

    $('.show-password-icon').on('click', function () {
      $(this).parent().find('.hide-password-icon').css('display','block');
      $(this).parent().find('input[type="password"]').attr('type', 'text');
      $(this).css('display','none');
    });

    $('.hide-password-icon').on('click', function () {
      $(this).parent().find('.show-password-icon').css('display','block');
      $(this).parent().find('input[type="text"]').attr('type', 'password');
      $(this).css('display', 'none');
    });
  }

  function checkPasswordRequirements(passwordId) {
    const passwordInputValue = $('#' + passwordId)[0].value;
    const hasLowerCase = /[a-z]/.test(passwordInputValue);
    const hasUpperCase = /[A-Z]/.test(passwordInputValue);
    const hasNumber = /[0-9]/.test(passwordInputValue);
    const hasSpecial = /[^a-zA-Z0-9]/.test(passwordInputValue);
    const isFrClassExists = document.querySelector('.js-isFr') != null;

    const passwordMeetsAllRequirements = new RegExp($('#' + passwordId).attr('data-parsley-pattern')).test(passwordInputValue);

    let passwordsMatch = false;
    let isConfirmPasswordField = false;
    if ($('#' + passwordId).attr('data-parsley-equalto')) {
      passwordsMatch = (passwordInputValue === $($('#' + passwordId).attr('data-parsley-equalto'))[0].value);
      isConfirmPasswordField = true;
    }
    $('.password-requirements[data-password-input="' + passwordId + '"] .password-lowercase').toggleClass('pass',hasLowerCase);
    $('.password-requirements[data-password-input="' + passwordId + '"] .password-uppercase').toggleClass('pass',hasUpperCase);
    $('.password-requirements[data-password-input="' + passwordId + '"] .password-number').toggleClass('pass',hasNumber);
    $('.password-requirements[data-password-input="' + passwordId + '"] .password-special').toggleClass('pass',hasSpecial);
    $('.password-requirements[data-password-input="' + passwordId + '"] .password-character-count').toggleClass('pass',(passwordInputValue.toString().length >= 8));
      $('.password-requirements[data-password-input="' + passwordId + '"] .password-character-count-10').toggleClass('pass', (passwordInputValue.toString().length >= 10));
      $('.password-requirements[data-password-input="' + passwordId + '"] .password-character-count-15').toggleClass('pass', (passwordInputValue.toString().length >= 15));
    $('.password-requirements[data-password-input="' + passwordId + '"] .password-match').toggleClass('pass',passwordsMatch);
    $('.password-requirements[data-password-input="' + passwordId + '"] .password-match').closest('.password-requirements').toggleClass('error', !(passwordsMatch));

    let confirmPasswordText = 'Passwords Match';
    const isFrench = navigator.language.toLowerCase().indexOf('fr') >= 0 || (isFrClassExists ? document.querySelector('.js-isFr').classList.contains('active') : false);

    if (passwordsMatch) {
      confirmPasswordText = (isFrench)? 'Correspondance des mots de passe' : 'Passwords Match' 
    }
    else {
      confirmPasswordText = (isFrench)? 'Les mots de passe ne correspondent pas' : 'Passwords do not match'
    }

    $('.password-requirements[data-password-input="' + passwordId + '"] .password-match').text(confirmPasswordText);

    if(passwordMeetsAllRequirements && (!isConfirmPasswordField || passwordsMatch)) {
      // Password is valid
      $('.password-requirements[data-password-input="' + passwordId + '"] .password-requirements-validation').slideUp(100, function () {
        $('.password-requirements[data-password-input="' + passwordId + '"] .password-requirements-met').slideDown(100)
      });
    } else {
      // Password is invalid or does not match
      $('.password-requirements[data-password-input="' + passwordId + '"] .password-requirements-met').slideUp(100, function () {
        $('.password-requirements[data-password-input="' + passwordId + '"] .password-requirements-validation').slideDown(100)
      });
    }
  }

  function passwordInputChange(passwordInput) {
    if ($('.password-requirements').length) {
      checkPasswordRequirements($(passwordInput).attr('id'));
      const passwordEqualToInput = $('input[data-parsley-equalto="#' + $(passwordInput).attr('id') + '"]');
      if (passwordEqualToInput.length) {
        checkPasswordRequirements(passwordEqualToInput.attr('id'));
      }
    }
    if ($(passwordInput)[0].value !== '') {
      $(passwordInput).parent().siblings('label.floating').addClass('up');
      $(passwordInput).parent().siblings('label.floating').removeClass('down');
    } else {
      $(passwordInput).parent().siblings('label.floating').addClass('down');
      $(passwordInput).parent().siblings('label.floating').removeClass('up');
    }
  }

  initPasswordIcons();

  if (typeof window.Parsley !== 'undefined') {
    window.Parsley.on('form:error', function () {
      $('.password-requirements').addClass('error');
      $('.show-password-field').parent().siblings('label.floating').addClass('up');
      $('.show-password-field').parent().siblings('label.floating').removeClass('down');
    });
  }

  ['change', 'keyup', 'keydown', 'click'].forEach(function(eventName) {
    window.addEventListener(eventName, function () {
      if (event.target && event.target.classList.contains('show-password-field')) {
        passwordInputChange(event.target);
        if (document.querySelectorAll('.show-password-icon').length == 0) {
          initPasswordIcons();
        }
      }
    }, false);
  })
});